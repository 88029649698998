.downloadButton {
  background-color: rgba(18, 18, 18, 0.95) !important;
  border: 2px solid var(--purple-50) !important;
  padding: 1rem 2rem !important;
  min-width: 300px;
  transition: all 0.3s ease !important;
  height: auto !important;
}

.downloadButton:hover {
  transform: scale(1.05);
  border-color: var(--purple-80) !important;
}

.downloadButton:active {
  transform: scale(1);
}

@media (max-width: 480px) {
  .downloadButton {
    min-width: 200px;
    padding: 1rem 1.5rem !important;
  }
}
