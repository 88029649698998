:root {
  --purple: #9262f3;
  --purple2: #bdadff;
  --purple-dark: #7b4de8;
  --white: #ffffff;
  --black: #000000;
  --black-light: #121212;
  --black-lighter: #1a1a1a;
  --grey: #4e4f5f;

  /* Common alpha variations */
  --purple-10: rgba(146, 98, 243, 0.1);
  --purple-20: rgba(146, 98, 243, 0.2);
  --purple-50: rgba(146, 98, 243, 0.5);
  --purple-80: rgba(146, 98, 243, 0.8);

  /* Common gradients */
  --purple-gradient: linear-gradient(
    135deg,
    var(--purple2) 0%,
    var(--purple) 50%,
    var(--purple-dark) 100%
  );

  --dark-gradient: linear-gradient(
    145deg,
    var(--black) 0%,
    var(--black-light) 50%,
    var(--black-lighter) 100%
  );

  /* Additional alpha variations */
  --purple-15: rgba(146, 98, 243, 0.15);
  --purple-30: rgba(146, 98, 243, 0.3);
  --purple-40: rgba(146, 98, 243, 0.4);

  /* Background with opacity */
  --black-95: rgba(18, 18, 18, 0.95);

  /* Glossy backgrounds */
  --glass-bg: rgba(18, 18, 18, 0.5);
  --glass-blur-sm: blur(12px);
  --glass-blur-lg: blur(16px);
}
