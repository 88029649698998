@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseShadow {
  0% {
    filter: drop-shadow(0 0 20px var(--purple-20))
      drop-shadow(0 0 40px var(--purple-20))
      drop-shadow(0 0 60px var(--purple-10));
  }
  50% {
    filter: drop-shadow(0 0 25px var(--purple-40))
      drop-shadow(0 0 50px var(--purple-30))
      drop-shadow(0 0 75px var(--purple-20));
  }
  100% {
    filter: drop-shadow(0 0 20px var(--purple-20))
      drop-shadow(0 0 40px var(--purple-20))
      drop-shadow(0 0 60px var(--purple-10));
  }
}

.fadeIn {
  animation: fadeIn 1s ease-out;
}

.pulseShadow {
  animation: pulseShadow 3s ease-in-out infinite;
}

.spinOnLoad {
  animation: spinOnce 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hoverUnderline {
  position: relative;
}

.hoverUnderline::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: var(--purple);
  transition: width 0.3s ease;
}

.hoverUnderline:hover::after {
  width: 100%;
}

.hoverText:hover {
  color: var(--purple);
}

.socialLink {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.socialLink:hover {
  opacity: 1;
  filter: drop-shadow(0 0 8px var(--purple-50));
}

.purpleHoverLink {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.purpleHoverLink:hover {
  opacity: 1;
  color: var(--purple) !important;
  filter: drop-shadow(0 0 8px var(--purple-50));
}
